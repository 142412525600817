import React from "react"

const FpCounter = props => {
  return (
    <div className={`fp-counter ${props.fpc > 0 ? "is-active" : ""}`}>
      <h3 className={`mb-0`}>
        <span className={`text-secondary`}>0</span>
        <span className={[props.fpc > 0 && "text-white"]}>{props.fpc}</span>
      </h3>
      <div className={`total`}>&nbsp;{"/ 0" + (props.fpt - 1)}</div>
    </div>
  )
}

export default FpCounter
