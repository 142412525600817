import React from "react"

const Logo = () => {
  const nextSection = e => {
    e.preventDefault()
    const fullpage_api = window.fullpage_api
    fullpage_api.moveTo(2)
  }

  return (
    <button className={`logo-title`} onClick={e => nextSection(e)}>
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M67.3951 30.4896C62.3183 35.5664 54.0872 35.5664 49.0104 30.4896C43.9336 25.4128 43.9336 17.1816 49.0104 12.1048C54.0872 7.02798 62.3183 7.02798 67.3951 12.1048C72.472 17.1816 72.472 25.4128 67.3951 30.4896ZM34.984 62.9007L62.1001 35.7846C59.7429 36.4164 57.2617 36.4641 54.8858 35.9278L39.295 51.5186L37.8808 50.1044L52.7214 35.2639C51.1485 34.6475 49.6588 33.7555 48.3265 32.5877L11.6677 69.2465L10.2535 67.8323L46.9122 31.1735C45.7445 29.8412 44.8524 28.3514 44.2361 26.7786L34.2242 36.7904L32.81 35.3762L43.5721 24.6141C43.0358 22.2382 43.0835 19.7571 43.7153 17.3999L20.0482 41.067L18.634 39.6528L47.5962 10.6906C53.454 4.83272 62.9515 4.83272 68.8094 10.6906C74.6672 16.5485 74.6672 26.0459 68.8094 31.9038L36.3983 64.3149L34.984 62.9007Z"
          fill="#95A2B3"
        />
      </svg>
      Explore
    </button>
  )
}

export default Logo
