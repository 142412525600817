import React, { useState, useEffect } from "react"
import Header from "./Header"
import Sidebar from "./Sidebar"
import SEO from "../SEO/SEO"

const Layout = props => {
  const [windowWidth, setWindowWidth] = useState(0)

  const wwToProps = () => {
    props.wwCallback(windowWidth)
  }

  useEffect(() => {
    handleResize()
    function handleResize() {
      setWindowWidth(window.innerWidth)
      wwToProps()
    }
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <div>
      <SEO />
      <Header fpc={props.fpc} fpt={props.fpt} ww={windowWidth} />
      <Sidebar fpc={props.fpc} fpt={props.fpt} copyright={props.copyright} />
      {props.children}
    </div>
  )
}

export default Layout
