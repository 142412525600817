import VisibilitySensor from "react-visibility-sensor"

import React from "react"

const AnimatedContent = props => {
  return (
    <VisibilitySensor
      delayedCall={true}
      intervalDelay={props.delay ? props.delay : 250}
      partialVisibility={true}
    >
      {({ isVisible }) => (
        <div
          className={`${
            props.customClass ? props.customClass : "animated-content"
          }
          ${isVisible ? "is-visible" : ""}`}
        >
          {props.children}
        </div>
      )}
    </VisibilitySensor>
  )
}

export default AnimatedContent
