import React, { useState, useEffect } from "react"
import VisibilitySensor from "react-visibility-sensor"
import AnimatedTitle from "../UI/AnimatedTitle"
import FpCounter from "../UI/FpCounter"
import Logo from "../Logo/Logo"

const Header = props => {
  const menuTitles = ["About", "Work", "Contact"]
  const [menuOpen, menuOpenHandler] = useState(false)
  const [menuBtnText, menuBtnTextHandler] = useState(true)
  const [menuMobileDir, setMobileMenuDir] = useState(0)
  const moveToSection = (e, i) => {
    menuOpenHandler(false)
    const fp_api = window.fullpage_api
    e.preventDefault()
    i = i + 2
    props.fpc < i ? setMobileMenuDir(0) : setMobileMenuDir(1)
    fp_api.moveTo(i)
  }

  const animateMenuBtn = () => {
    menuBtnTextHandler(false)
    setTimeout(() => {
      menuBtnTextHandler(true)
    }, 100)
  }

  useEffect(() => {
    animateMenuBtn()
  }, [menuOpen])

  const menuItems = menuTitles.map((title, i) => {
    return (
      <div className="menu-item" key={i}>
        <a
          href={`#${menuTitles[i]}`}
          className={`menu-item__link ${props.fpc == i + 1 ? "is-active" : ""}`}
          onClick={e => moveToSection(e, i)}
        >
          <AnimatedTitle
            title={title}
            tag={`span`}
            size={` `}
            delay={(props.ww < 1020 ? 300 : 2000) + 100 * i}
          />
        </a>
      </div>
    )
  })

  return (
    <header
      className={`header container-fluid fluid-xl-wide  ${
        props.fpc > 0 ? "is-scrolling" : ""
      }`}
    >
      <div className={`header-logo-wrapper`}>
        <Logo />
        <FpCounter fpc={props.fpc} fpt={props.fpt} />
      </div>
      <VisibilitySensor>
        {({ isVisible }) => (
          <nav
            className={[
              `header-menu text-secondary`,
              isVisible ? "is-visible" : "",
              menuMobileDir ? "mobile-dir-reversed" : "",
              menuOpen ? "is-open" : "",
            ].join(" ")}
          >
            {menuItems}
          </nav>
        )}
      </VisibilitySensor>
      <button
        className={`menu-btn ${menuOpen ? "is-active" : ""}`}
        onClick={() => menuOpenHandler(!menuOpen)}
      >
        {menuOpen ? (
          <span className={`animated-title ${menuBtnText ? "is-visible" : ""}`}>
            <span>C</span>
            <span>l</span>
            <span>o</span>
            <span>s</span>
            <span>e</span>
          </span>
        ) : (
          <span className={`animated-title ${menuBtnText ? "is-visible" : ""}`}>
            <span>M</span>
            <span>e</span>
            <span>n</span>
            <span>u</span>
          </span>
        )}
      </button>
    </header>
  )
}

export default Header
