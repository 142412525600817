import React from "react"
import VisibilitySensor from "react-visibility-sensor"

const Socials = props => {
  return (
    <div className={`socials-wrapper ${props.vertical ? "is-vertical" : ""}`}>
      <VisibilitySensor>
        {({ isVisible }) => (
          <a
            href="https://github.com/Kureyko"
            className={`social-btn ${isVisible ? "is-visible" : ""}`}
            target="_blank"
          >
            <svg
              role="img"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#95A2B3"
                d="M12 .297c-6.63 0-12 5.373-12 12 0 5.303 3.438 9.8 8.205 11.385.6.113.82-.258.82-.577 0-.285-.01-1.04-.015-2.04-3.338.724-4.042-1.61-4.042-1.61C4.422 18.07 3.633 17.7 3.633 17.7c-1.087-.744.084-.729.084-.729 1.205.084 1.838 1.236 1.838 1.236 1.07 1.835 2.809 1.305 3.495.998.108-.776.417-1.305.76-1.605-2.665-.3-5.466-1.332-5.466-5.93 0-1.31.465-2.38 1.235-3.22-.135-.303-.54-1.523.105-3.176 0 0 1.005-.322 3.3 1.23.96-.267 1.98-.399 3-.405 1.02.006 2.04.138 3 .405 2.28-1.552 3.285-1.23 3.285-1.23.645 1.653.24 2.873.12 3.176.765.84 1.23 1.91 1.23 3.22 0 4.61-2.805 5.625-5.475 5.92.42.36.81 1.096.81 2.22 0 1.606-.015 2.896-.015 3.286 0 .315.21.69.825.57C20.565 22.092 24 17.592 24 12.297c0-6.627-5.373-12-12-12"
              />
            </svg>
          </a>
        )}
      </VisibilitySensor>
      <VisibilitySensor>
        {({ isVisible }) => (
          <a
            href="https://www.linkedin.com/in/evgeny-kureyko-6863391a1/"
            className={`social-btn ${isVisible ? "is-visible" : ""}`}
            target="_blank"
          >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 382 382">
              <path
                fill="#95A2B3"
                d="M347.445,0H34.555C15.471,0,0,15.471,0,34.555v312.889C0,366.529,15.471,382,34.555,382h312.889  C366.529,382,382,366.529,382,347.444V34.555C382,15.471,366.529,0,347.445,0z M118.207,329.844c0,5.554-4.502,10.056-10.056,10.056  H65.345c-5.554,0-10.056-4.502-10.056-10.056V150.403c0-5.554,4.502-10.056,10.056-10.056h42.806  c5.554,0,10.056,4.502,10.056,10.056V329.844z M86.748,123.432c-22.459,0-40.666-18.207-40.666-40.666S64.289,42.1,86.748,42.1  s40.666,18.207,40.666,40.666S109.208,123.432,86.748,123.432z M341.91,330.654c0,5.106-4.14,9.246-9.246,9.246H286.73  c-5.106,0-9.246-4.14-9.246-9.246v-84.168c0-12.556,3.683-55.021-32.813-55.021c-28.309,0-34.051,29.066-35.204,42.11v97.079  c0,5.106-4.139,9.246-9.246,9.246h-44.426c-5.106,0-9.246-4.14-9.246-9.246V149.593c0-5.106,4.14-9.246,9.246-9.246h44.426  c5.106,0,9.246,4.14,9.246,9.246v15.655c10.497-15.753,26.097-27.912,59.312-27.912c73.552,0,73.131,68.716,73.131,106.472  L341.91,330.654L341.91,330.654z"
              />
            </svg>
          </a>
        )}
      </VisibilitySensor>
      <VisibilitySensor>
        {({ isVisible }) => (
          <a
            href="https://www.instagram.com/evgeny.co/"
            className={`social-btn ${isVisible ? "is-visible" : ""}`}
            target="_blank"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.4805 0H5.51913C2.47588 0 0 2.476 0 5.51924V14.4806C0 17.524 2.47588 19.9999 5.51913 19.9999H14.4805C17.524 19.9999 19.9999 17.5239 19.9999 14.4806V5.51924C20 2.476 17.524 0 14.4805 0ZM18.2255 14.4806C18.2255 16.5455 16.5455 18.2254 14.4806 18.2254H5.51913C3.45433 18.2255 1.77449 16.5455 1.77449 14.4806V5.51924C1.77449 3.45445 3.45433 1.77449 5.51913 1.77449H14.4805C16.5454 1.77449 18.2254 3.45445 18.2254 5.51924V14.4806H18.2255Z"
                fill="#95A2B3"
              />
              <path
                d="M9.9999 4.84671C7.15824 4.84671 4.84644 7.15851 4.84644 10.0002C4.84644 12.8417 7.15824 15.1534 9.9999 15.1534C12.8416 15.1534 15.1534 12.8417 15.1534 10.0002C15.1534 7.15851 12.8416 4.84671 9.9999 4.84671ZM9.9999 13.3788C8.13681 13.3788 6.62092 11.8631 6.62092 10.0001C6.62092 8.13684 8.13669 6.62108 9.9999 6.62108C11.8631 6.62108 13.3789 8.13684 13.3789 10.0001C13.3789 11.8631 11.863 13.3788 9.9999 13.3788Z"
                fill="#95A2B3"
              />
              <path
                d="M15.3695 3.34207C15.0276 3.34207 14.6918 3.48048 14.4503 3.72299C14.2077 3.96432 14.0682 4.30029 14.0682 4.64336C14.0682 4.98536 14.2078 5.32122 14.4503 5.56373C14.6917 5.80506 15.0276 5.94465 15.3695 5.94465C15.7126 5.94465 16.0474 5.80506 16.2899 5.56373C16.5324 5.32122 16.6708 4.98525 16.6708 4.64336C16.6708 4.30029 16.5324 3.96432 16.2899 3.72299C16.0486 3.48048 15.7126 3.34207 15.3695 3.34207Z"
                fill="#95A2B3"
              />
            </svg>
          </a>
        )}
      </VisibilitySensor>
    </div>
  )
}

export default Socials
