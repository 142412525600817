import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactHtmlParser from "react-html-parser"
import ReactFullpage from "@fullpage/react-fullpage"
import Slider from "react-slick"
import Img from "gatsby-image/withIEPolyfill"

import Layout from "../components/Layout/Layout"
import Socials from "../components/UI/Socials"
import Title from "../components/UI/AnimatedTitle"
import AnimatedTitle from "../components/UI/AnimatedTitle"
import AnimatedContent from "../components/UI/AnimatedContent"
import Background from "../components/Background/Background"

const SEL = "fp-section"
const SECTION_SEL = `.${SEL}`

const query = graphql`
  query onePageQuery {
    content: wordpressPage(slug: { eq: "home-page" }) {
      fields: acf {
        home_about_content
        home_about_title
        home_about_columns {
          home_about_column
        }
        home_contact_other
        home_contact_text
        home_contact_title
        home_landing_description
        home_landing_title_1
        home_landing_title_2
        home_landing_title_3
        home_other_copyright
        home_work {
          home_work_description
          home_work_title
        }
        home_work_title
      }
    }
    image1: imageSharp(fluid: { originalName: { eq: "background.png" } }) {
      id
      fluid(maxWidth: 2560, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
    image2: imageSharp(fluid: { originalName: { eq: "sputnik_filter.png" } }) {
      id
      fluid(maxWidth: 2560, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`

const Home = props => {
  const slider = React.createRef()
  const [fpIndex, setFpIndex] = useState(0)
  const [tabActive, setTabActive] = useState(0)
  const [mTabHeight, setMTabHeight] = useState([])
  const [fpSection, setFpSection] = useState(null)
  const [windowWidth, setWindowWidth] = useState(0)
  const fpTooltips = ["", "About", "Work", "Contact"]

  const tabsHandler = index => {
    setTabActive(index)
    if (slider.current.innerSlider) {
      slider.current.slickGoTo(index)
    } else {
      window.fullpage_api.moveTo(3)
      overflowReset(fpSection, 500, 0)
    }
  }

  const overflowReset = (section, speed = 0, timeout = 1000) => {
    const scrollable = section.item.querySelector(".fp-scrollable")
    if (scrollable) {
      const instance = scrollable.fp_iscrollInstance
      setTimeout(() => {
        instance.scrollTo(0, 0, speed)
      }, timeout)
    }
  }

  const slickSettings = {
    dots: false,
    infinite: false,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    draggable: false,
    adaptiveHeight: true,
    centerMode: true,
    centerPadding: 0,
    fade: true,
    responsive: [
      {
        breakpoint: 1020,
        settings: "unslick",
      },
    ],
  }

  /**
   * * Main Query
   */
  const {
    content: { fields: data },
    image1,
    image2,
  } = useStaticQuery(query)

  // Create dynamic mobile tabs refs
  let mobileTabRefs = []
  data.home_work.map((val, index) => {
    const ref = React.createRef()
    mobileTabRefs[index] = ref
  })

  useEffect(() => {
    function handleResize() {
      let arr = []
      mobileTabRefs.map(ref => {
        if (ref.current) {
          arr.push(ref.current.clientHeight)
        }
      })
      setTimeout(() => {
        setMTabHeight(arr)
      }, 1000)
    }

    handleResize()

    window.addEventListener("resize", handleResize)

    return () => {
      // cleanup
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Layout
      heightChange={tabActive}
      fpc={fpIndex}
      fpt={fpTooltips.length}
      copyright={data.home_other_copyright}
      wwCallback={value => setWindowWidth(value)}
    >
      <main>
        <ReactFullpage
          // Required when using extensions
          // pluginWrapper={pluginWrapper}

          licenseKey={`42E4FD83-0CAE4866-B7C196EC-E569F6B0`}
          // fullpage options
          touchSensitivity={15}
          sectionSelector={SECTION_SEL}
          scrollOverflow={true}
          onLeave={(origin, destination) => {
            overflowReset(origin)
            setFpIndex(destination.index)
            setFpSection(destination)
          }}
          render={({ fullpageApi }) => (
            <ReactFullpage.Wrapper>
              <section className={`${SEL} landing-section is-slim`}>
                <div className="global-section h-100">
                  <div className="container-fluid h-100">
                    <div className="row h-100 justify-content-between">
                      <div className="col-xl-7">
                        <div className="landing-title">
                          <AnimatedTitle
                            title={`${data.home_landing_title_1}`}
                            size="h3 text-secondary"
                            delay={500}
                          />
                          <AnimatedTitle
                            title={`${data.home_landing_title_2}`}
                            delay={500}
                          />
                          <AnimatedTitle
                            title={`${data.home_landing_title_3}`}
                            size="h3 text-secondary"
                            delay={500}
                          />
                          <br />
                        </div>
                      </div>
                      <div className="col-xl-5 align-self-end">
                        <AnimatedContent delay={windowWidth < 768 ? 500 : 1500}>
                          {ReactHtmlParser(data.home_landing_description)}
                          <button
                            className="custom-btn"
                            onClick={() => fullpageApi.moveSectionDown()}
                          >
                            About
                          </button>
                        </AnimatedContent>
                      </div>
                    </div>
                  </div>
                </div>
                <Background wrapperClass={`is-mobile-opacity`}>
                  <Img
                    fluid={image1.fluid}
                    objectFit="cover"
                    objectPosition="50% 100%"
                    alt={`Photo by Galen Crout on Unsplash @galen_crout`}
                  />
                </Background>
              </section>
              <section className={`${SEL} bg-dark text-white`}>
                <div className="global-section">
                  <div className="container-fluid">
                    <Title title={`${data.home_about_title}`} />
                    <div className="text-secondary section-border fp-animated">
                      <div className="section-border__inner">
                        {ReactHtmlParser(data.home_about_content)}
                        <div className="row">
                          {data.home_about_columns.map(
                            ({ home_about_column: info }, index) => {
                              if (info) {
                                return (
                                  <div
                                    className="col-md-6 mt-4"
                                    key={`home_about_column_${index}`}
                                  >
                                    {ReactHtmlParser(info)}
                                  </div>
                                )
                              }
                            }
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className={`${SEL} text-white`}>
                <div className="global-section h-100">
                  <div className="container-fluid h-100">
                    <div className="row align-items-center h-100">
                      <div className="col-xl-4 col-hd-3">
                        <Title title={`${data.home_work_title}`} />
                        <AnimatedContent>
                          <ul className="tabs-navigation d-none d-xl-block">
                            {data.home_work.map(
                              ({ home_work_title }, index) => {
                                return (
                                  <li
                                    className={[
                                      "tab-btn",
                                      tabActive === index ? "is-active" : "",
                                    ].join(" ")}
                                    onClick={() => tabsHandler(index)}
                                    key={`home_work_title_${index}`}
                                  >
                                    {ReactHtmlParser(home_work_title)}
                                  </li>
                                )
                              }
                            )}
                          </ul>
                        </AnimatedContent>
                        <div className="d-xl-none">
                          <div className="mobile-tabs custom-border">
                            {data.home_work.map(
                              (
                                { home_work_title, home_work_description },
                                index
                              ) => {
                                return (
                                  <div
                                    className="mobile-tab"
                                    key={`home_work_title_mobile_${index}`}
                                  >
                                    <div
                                      className={[
                                        "mobile-tab__btn",
                                        tabActive === index ? "is-active" : "",
                                      ].join(" ")}
                                      onClick={() => tabsHandler(index)}
                                    >
                                      {ReactHtmlParser(home_work_title)}
                                    </div>
                                    <div
                                      className={`mobile-tab__content text-secondary ${
                                        tabActive === index ? "is-active" : ""
                                      }`}
                                      style={{
                                        maxHeight: mTabHeight[index] + "px",
                                      }}
                                    >
                                      <div
                                        className="mobile-tab__content-inner"
                                        ref={mobileTabRefs[index]}
                                      >
                                        {ReactHtmlParser(home_work_description)}
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-8 col-hd-9 d-none d-xl-block">
                        <div className="section-border fp-animated">
                          <div className="section-border__inner">
                            <div className="tabs-wrapper text-secondary">
                              <Slider
                                ref={slider}
                                {...slickSettings}
                                id="tab-content-slick"
                              >
                                {data.home_work.map(
                                  ({ home_work_description }, index) => {
                                    return (
                                      <div
                                        className="tab-content"
                                        key={`home_work_description_${index}`}
                                      >
                                        {ReactHtmlParser(home_work_description)}
                                      </div>
                                    )
                                  }
                                )}
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <Background>
                  <img
                    src="covers/nasa_astronaut.png"
                    alt="Photo by NASA on Unsplash"
                    style={{ opacity: "0.3" }}
                  />
                </Background> */}
              </section>
              <section className={`${SEL} text-white`}>
                <div className="global-section for-footer h-100">
                  <div className="container-fluid d-flex flex-column h-100">
                    <Title title={`${data.home_contact_title}`} />
                    <div className="row flex-grow-1 text-secondary">
                      <div className="col-lg-6">
                        <AnimatedContent>
                          {ReactHtmlParser(data.home_contact_text)}
                        </AnimatedContent>
                      </div>
                      {data.home_contact_other ? (
                        <div className="col-lg-6 align-self-end">
                          <AnimatedContent>
                            {ReactHtmlParser(data.home_contact_other)}
                            <Socials />
                          </AnimatedContent>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="d-lg-none mt-4">
                      <div className="font-size-sm text-secondary">
                        <AnimatedContent>
                          {ReactHtmlParser(data.home_other_copyright)}
                        </AnimatedContent>
                      </div>
                    </div>
                  </div>
                </div>
                <Background>
                  <Img
                    fluid={image2.fluid}
                    objectFit="contain"
                    objectPosition="50% 50%"
                    alt={``}
                  />
                </Background>
              </section>
            </ReactFullpage.Wrapper>
          )}
        />
      </main>
    </Layout>
  )
}

export default Home
