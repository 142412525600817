import React, { useState } from "react"
import VisibilitySensor from "react-visibility-sensor"

const AnimatedTitle = props => {
  const [inits, setInits] = useState(0)

  let title = []
  props.title
    .replace(/<br \/>/g, "|")
    .split(" ")
    .map((word, i) => {
      let newWord = ""
      word.split("").map(char => {
        newWord += char == "|" ? "<br />" : "<span>" + char + "</span>"
      })
      title.push(`${newWord}`)
    })

  title = title.join(" ")

  const CustomTag = props.tag ? `${props.tag}` : `h2`

  return (
    <VisibilitySensor
      delayedCall={true}
      intervalDelay={props.delay ? props.delay : 250}
      onChange={() => setInits(inits + 1)}
    >
      {({ isVisible }) => (
        <CustomTag
          className={`${props.size ? props.size : "h1"} animated-title ${
            isVisible ? "is-visible" : ""
          }`}
          dangerouslySetInnerHTML={{ __html: title }}
        ></CustomTag>
      )}
    </VisibilitySensor>
  )
}

export default AnimatedTitle
