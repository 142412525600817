import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

const SiteMetadata = ({ pathname, locale }) => {
  const {
    site: {
      siteMetadata: { siteUrl, title, canonical, description, themeColor },
    },
    ogimage,
  } = useStaticQuery(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          siteUrl
          title
          canonical
          description
          title
          themeColor
        }
      }
      ogimage: imageSharp(fixed: { originalName: { eq: "og_image.png" } }) {
        fixed(width: 1260) {
          src
          width
          height
        }
      }
    }
  `)

  return (
    <Helmet
      encodeSpecialCharacters={true}
      titleTemplate={`${title} · %s`}
      defaultTitle={`${title} · Web Developer`}
    >
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta name="theme-color" content={`${themeColor}`} />
      {ogimage && <meta name="og:image" content={`${ogimage.fixed.src}`} />}
      <meta property="og:url" content={siteUrl} />
      <meta property="og:title" content={`${title} · Web Developer`} />
      <meta property="og:description" content={`${description}`} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content={locale} />
      <meta property="og:site_name" content={title} />
      {ogimage && (
        <meta property="og:image:width" content={`${ogimage.fixed.width}`} />
      )}
      {ogimage && (
        <meta property="og:image:height" content={`${ogimage.fixed.height}`} />
      )}
    </Helmet>
  )
}

export default SiteMetadata
