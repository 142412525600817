import React from "react"

const Background = props => {
  return (
    <div
      className={[
        `background-cover`,
        props.wrapperClass && props.wrapperClass,
      ].join(" ")}
    >
      {props.children}
    </div>
  )
}

export default Background
