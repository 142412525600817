import React from "react"
import Socials from "../UI/Socials"
import ReactHtmlParser from "react-html-parser"
import AnimatedContent from "../UI/AnimatedContent"
import FpCounter from "../UI/FpCounter"
import Logo from "../Logo/Logo"

const Sidebar = props => {
  const styles = {
    progress: {
      height: (props.fpc / (props.fpt - 1)) * 100 + "%",
    },
  }

  return (
    <aside className={`sidebar`}>
      <div
        className={`sidebar-logo-wrapper ${
          props.fpc > 0 ? "is-scrolling" : ""
        }`}
      >
        <Logo />
        <FpCounter fpc={props.fpc} fpt={props.fpt} />
      </div>
      <div className="scroll-progress-wrapper">
        <span className="scroll-progress" style={styles.progress}></span>
      </div>
      <Socials vertical />
      <AnimatedContent customClass={`disclaimer`}>
        {ReactHtmlParser(props.copyright)}
      </AnimatedContent>
    </aside>
  )
}

export default Sidebar
